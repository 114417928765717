import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Surface from "@elements/Surface"
import Vimeo from "@elements/Vimeo"
import Block from "@global/layout/Block"
import Splash from "@layouts/splash"
import { GatsbyImage } from "gatsby-plugin-image"
import SwiperCore, { Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.css"
import typography from "@helpers/typography"
import { HelmetDatoCms } from "gatsby-source-datocms"
import Button from "@elements/Button"
import { useAuth0 } from "@utils/auth"
import { ProtectedRoute } from "../components/ProtectedRoute"
SwiperCore.use([Pagination])

const permissions = `${process.env.GATSBY_AUTH0_NAMESPACE}permissions`

const Tour = ({ data }) => {
  const { loading, user } = useAuth0()
  typography.injectStyles()
  const { site, tour } = data
  const { tourSteps, exitLink } = tour

  const buttonConfig = {
    text: exitLink.displayText,
    secondary: true,
    to: exitLink.link ? exitLink.link.path : exitLink.url,
    maxWidth: true
  }

  useEffect(() => {
    if (user) {
      const { email, first_name, last_name } = user[permissions].customer
      const submitForm = async () => {
        try {
          const response = await fetch("/.netlify/functions/registerHubspot", {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({ email, first_name, last_name })
          })

          if (response.ok) {
            console.log("Form submitted successfully")
          } else {
            console.error("Form submission failed", response)
          }
        } catch (error) {
          console.error("Error submitting form", error)
        }
      }

      submitForm()
    }
  }, [user])

  return (
    <Splash bgColor={"primary"}>
      <ProtectedRoute>
        <HelmetDatoCms defer={false} seo={site.seoMetaTags} />
        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          pagination={{ clickable: true }}
          centeredSlides={true}
        >
          {tourSteps.map(
            ({ heading, topText, bottomText, media, id, video }) => (
              <SwiperSlide key={`slide-${id}`}>
                <Surface
                  heading={heading}
                  padding="both"
                  elevated={true}
                  margin="slight"
                >
                  <Block padding="bottom" gutters={true}>
                    <p>{topText}</p>
                  </Block>
                  {media && <GatsbyImage image={media.gatsbyImageData} />}
                  {video && <Vimeo videoUrl={video} />}
                  <Block padding="top" gutters={true}>
                    <p>{bottomText}</p>
                    <Button {...buttonConfig} />
                  </Block>
                </Surface>
              </SwiperSlide>
            )
          )}
        </Swiper>
      </ProtectedRoute>
    </Splash>
  )
}

export const query = graphql`
  query TourById($id: String) {
    tour: datoCmsTour(id: { eq: $id }) {
      id
      slug
      title
      tourSteps {
        ...TourStep
        ...TourStepVideo
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      exitLink {
        ...LinkHelper
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default Tour
